



















































import BasePagePreloader from '@/components/BasePagePreloader.vue'
import { mapState } from 'vuex'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'NewUiModal',
  components: { BasePagePreloader },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Title'
    },
    width: {
      type: String,
      default: '600'
    },
    classes: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: 1920
    },
    minHeight: {
      type: Number,
      default: 0
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    shiftY: {
      type: Number,
      default: 0.2
    },
    adaptive: {
      type: Boolean,
      default: true
    },
    overflow: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    footerStyle: {
      type: String as PropType<'end' | 'reverse-end' | 'between' | 'reverse-between' | 'start' | 'reverse-start'>,
      default: ''
    }
  },
  computed: {
    ...mapState('interests', ['modalLoading'])
  },
  methods: {
    hide (name?: string): void {
      this.$modal.hide(typeof name === 'string' ? name : this.name)
    }
  }
})
